export const getLogoShortList = () => {
    /*
    Return a list of tags that we have vendor logo for.  This is a subset of what we defined inside getStaticLogoList.
    Perhaps we do not want to display all vendor that we have logo for, and perhaps we want to display this list in 
    as specific order.

    Perhaps we will want to deprecate this function, and wherever this function is used, just replace it with the keys 
    that are in the object returned by getStaticLogoList, sorted in the alphabetical order.

    The purpose of this function is to control the order of how we want to display tags that we have logo for.
    */
    return [
        'dagknows',
        'aws',
        'openvpn',
        'windows',
        'aim',
        'cloudfront',
        'cloudtrail',
        'cloudwatch',
        'dynamodb',
        'ec2',
        'ebs',
        'ecs',
        'elb',
        'iam',
        'rds',
        'redshift',
        'route53',
        'storage gateway',
        's3',
        'ssm',
        'vpc',

        'azure',
        'cisco',
        'confluence',
        'docker',
        'elastic',
        'elk',
        'gcp',
        'slack',
        'github',
        'jira',
        'zendesk',
        'kubernetes',
        'linux',
        'macos',
        'msteams',
        'opsgenie',
        'pagerduty',
        'postgresql',
        'prometheus',
        'servicenow',
        'ubuntu'
    ];
}
